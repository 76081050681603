// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Style
import Style from "./style.module.scss";

// Fields
import { Link } from "fields";

// Icons
import { PhoneRedIcon } from "assets/Icons";

class LetsChatBox extends React.Component {
  
  render() {
    const { description, heading = "Let's Chat" } = this.props;
    return (
      <div className={Style.letsChatBox}>
        <div className={Style.letsChatText}>
          <h2>{heading}</h2>
          <p>{description}</p>
        </div>
        <div className={Style.buttons}>
          <div
            className={Style.bookDemoButton}
            color="white"
            onClick={() => this.props.history.push("/get-started")}
          >
            Book a demo
          </div>
          <div className={Style.clickCallButton} color="white">
            <Link
              className={Style.callLink}
              href={`tel:4156109521`}
              target="_self"
            >
              <PhoneRedIcon className={Style.icon} /> Click to call
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

LetsChatBox.propTypes = {
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(LetsChatBox);
