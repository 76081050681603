// BUTI DINERS, INC. All right Reserved ©

import AboutShop from "./AboutShop";
import AddNewPaymentMethodModal from "./AddNewPaymentMethodModal";
import CategoryNavigationModal from "./CategoryNavigationModal";
import CloverCheckOutModal from "./CloverCheckOutModal";
import BenefitsModal from "./BenefitsModal";

import ConfirmModal from "./ConfirmModal";

// Confirm Delete Modals
import ConfirmDeleteItemFromCartModal from "./ConfirmDeleteModals/ConfirmDeleteItemFromCartModal";

// Customer
import CustomerProfileModal from "./CustomerProfileModal";
import LoadingModal from "./LoadingModal";
import MobileNavOptions from "./MobileNavOptions";
import OrderingPageMainMenu from "./OrderingPageMainMenu";
import MenuFilterModal from "./MenuFilterModal";
import VideoPlayerModal from "./VideoPlayerModal";

const Modals = {
  AboutShop,
  AddNewPaymentMethodModal,
  BenefitsModal,
  CategoryNavigationModal,
  CloverCheckOutModal,
  ConfirmDeleteItemFromCartModal,
  ConfirmModal,
  CustomerProfileModal,
  LoadingModal,
  MobileNavOptions,
  MenuFilterModal,
  OrderingPageMainMenu,
  VideoPlayerModal,
};

export default Modals;
