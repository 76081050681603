// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Assets
import {
  ShorterLinesIcon,
  UserFriendlyIcon,
  BrowsingTimeIcon,
  OrderAccuracyIcon,
} from "assets/Icons";

class Device extends React.Component {
  devices = [
    {
      name: "User friendly",
      icon: <UserFriendlyIcon />,
      description:
        "Intuitive interface makes ordering faster and more accurate",
    },
    {
      name: "Shorter lines",
      icon: <ShorterLinesIcon />,
      description: "Move lines quicker for happier repeat customers",
    },
    {
      name: "Browsing time",
      icon: <BrowsingTimeIcon />,
      description: "Give guests more time to browse and explore your menu",
    },
    {
      name: "Order accuracy",
      icon: <OrderAccuracyIcon />,
      description: "Guests enter their own orders avoiding employee errors",
    },
  ];
  render() {
    return (
      <div>
        <img
          alt=""
          src="https://images.ctfassets.net/v6ivjcl8qjz2/5we9cw6LOaXpCe1368OhtY/fa1628f67f1f9958e7741470fb080fed/customer_experience_graphic-array.svg"
          className={Style.device_image}
        />
        <div className={Style.device_grid}>
          {this.devices.map((device, index) => (
            <div key={index} className={Style.device_card}>
              {device.icon}
              <p className={Style.device_name}>{device.name}</p>
              <p className={Style.device_description}>{device.description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Device.propTypes = {
  context: PropTypes.shape({
    onScrollTo: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(withRouter(Device));
