// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";
import kiosk_hero_image from "assets/Images/kiosk_hero_image.jpg";

// Fields
import Button from "fields/Button";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

class CloverKioskIntro extends React.Component {
  renderButtons = () => (
    <div className={Style.buttons}>
      <Button
        className={Style.startNowBtn}
        hasShadow
        name="get started"
        onClick={() => this.props.history.push("/get-started")}
      >
        Get a Free Demo <ChevronArrowIcon className={Style.arrowIcon} />
      </Button>
    </div>
  );

  render() {
    return (
      <div className={Style.container}>
        <div className={Style.text_column}>
          <div className={Style.text_content}>
            <h1 className={Style.heading}>
              Clover Kiosk: self ordering for <span>Restaurants.</span>
            </h1>

            <p className={Style.description}>
              <span>$3,499 + $34.95/mo per device</span>
            </p>
            <p className={Style.description}>
              Our all-in-one device features an enterprise grade 24" display,
              versatile payment terminal, and a built-in printer, all with
              low-touch set up.
            </p>
            {this.renderButtons()}
          </div>
        </div>
        <div className={Style.image_column}>
          <img alt="" className={Style.hero_image} src={kiosk_hero_image} />
        </div>
      </div>
    );
  }
}

CloverKioskIntro.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(CloverKioskIntro)
);
