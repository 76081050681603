// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Style
import Style from "./style.module.scss";

// Fields
import Button from "fields/Button";

// Assets
import { QuantityCalculator, ResponsiveModal } from "fields";
import {
  AmexIcon,
  BankPayment,
  DiscoverIcon,
  MastercardIcon,
  VisaIcon,
} from "assets/Icons";

class CloverCheckOutModal extends React.Component {
  updateQuantity = (id, increment = 0) => {
    const cart = JSON.parse(localStorage.getItem("clover_cart")) || [];
    const itemIndex = cart.findIndex((item) => item.id === id);
    if (itemIndex === -1) return;

    const { quantity = 1 } = cart[itemIndex];
    const newQuantity = quantity + increment;

    let updatedCart;
    if (newQuantity < 1) {
      updatedCart = cart.filter((item, index) => index !== itemIndex);
    } else {
      updatedCart = cart.map((item, index) =>
        index === itemIndex ? { ...item, quantity: newQuantity } : item
      );
    }

    localStorage.setItem("clover_cart", JSON.stringify(updatedCart));
    this.setState({ cart_quantity: updatedCart });
    this.props.updateCart();
  };

  render() {
    const { cart = [] } = this.props;
    if (cart.length === 0) {
      this.props.onCloseCheckout();
      return null;
    }
    const total_quantity = cart.reduce((sum, item) => sum + item.quantity, 0);

    const shipping_cost = total_quantity * 300;
    return (
      <ResponsiveModal
        classNames={{
          contentContainer: Style.contentContainer,
          modal: Style.modal,
        }}
        contentLabel="item detail modal"
        onCloseModal={this.props.onCloseCheckout}
      >
        <div>
          <h2 className={Style.title}>In your cart</h2>
          <div className={Style.cartContainer}>
            <div className={Style.cartLeft}>
              {cart.length > 0 ? (
                cart.map((item = {}, index) => {
                  const {
                    img = "",
                    name = "",
                    price = 0,
                    quantity = 0,
                    id = "",
                  } = item;

                  return (
                    <div className={Style.item} key={index}>
                      <div className={Style.itemDetails}>
                        <div className={Style.product}>
                          <img
                            src={img}
                            alt={name}
                            className={Style.productImage}
                          />
                          <div>
                            <div className={Style.cart_item_name}>{name}</div>
                            <div className={Style.price_mid}>
                              ${price * quantity}
                            </div>
                            <div className={Style.quantityAndRemove}>
                              <QuantityCalculator
                                currentQuantity={quantity}
                                onButtonClick={(change) => {
                                  this.updateQuantity(id, change);
                                }}
                                plusButtonClassName={Style.plusButton}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={Style.price}>${price * quantity}</div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>Your cart is empty.</p>
              )}
            </div>
            <div className={Style.cartRight}>
              <h2>Checkout</h2>
              <div className={Style.summary}>
                <h4 className={Style.subTitle}>Due Today</h4>
                <div className={Style.paymentDetails}>
                  {cart.map((item = {}, index) => {
                    const { name = "", quantity = "", price = 0 } = item;
                    return (
                      <div className={Style.paymentRow} key={index}>
                        <span>
                          {name} ({quantity})
                        </span>
                        <span>${price * quantity}</span>
                      </div>
                    );
                  })}
                  <div className={Style.paymentRow}>
                    <span>Shipping</span>
                    <span>${shipping_cost}</span>
                  </div>
                </div>

                <Button className={Style.purchaseButton}>Purchase</Button>
                <div className={Style.payment_method_icon}>
                  <AmexIcon />
                  <DiscoverIcon />
                  <MastercardIcon />
                  <BankPayment />
                  <VisaIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ResponsiveModal>
    );
  }
}

CloverCheckOutModal.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(CloverCheckOutModal);
