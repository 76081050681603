// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { SplitOverlayGroup } from "components";
import clover_kiosk_self from "assets/Images/clover_kiosk_self.jpg";
import clover_kiosk_low_touch_setup from "assets/Images/clover_kiosk_low_touch_setup.jpg";

const WEB_DESIGN_FEATURES = [
  {
    action_button: { text: "Get A Free Demo" },
    label: "Self ordering, optimized",
    description:
      "Automate the ordering process to optimize staff time, enhance customer experience, and increase revenue through upsell and cross-sell features on the kiosk",
    image: clover_kiosk_self,
    list: [
      {
        item_name: "Boost efficiency",
        item_description:
          "Free up your staff to focus on value-added tasks rather than taking orders.",
      },
      {
        item_name: "Improve customer experience",
        item_description:
          "Let customers browse menus at their own pace and customize their orders the way they want.",
      },
      {
        item_name: "Grow your average ticket size",
        item_description:
          "Increase restaurant revenue by promoting cross-selling and upselling options on the ordering kiosk.",
      },
    ],
  },
  {
    action_button: { text: "Get A Free Demo" },
    label: "Low‑touch set up",
    description:
      "Effortless setup with guided, step-by-step instructions, syncing directly with your Clover POS system, and ensuring orders are processed just like those placed at the counter.",
    image: clover_kiosk_low_touch_setup,
    list: [
      {
        item_name: "Step-by-step instructions",
        item_description:
          "Guided set up walks through each step from checking for images to tipping and printer set up.",
      },
      {
        item_name: "Syncs with your POS",
        item_description:
          "Connects to and pulls your menu directly from your Clover POS system—keeping your items and pricing in sync.",
      },
      {
        item_name: "Orders in their place",
        item_description:
          "Orders show on your Kitchen Display System or print to your kitchen printer just as if they were placed at your counter.",
      },
    ],
  },
];

class WebsiteDesignFeatures extends React.Component {
  render() {
    return <SplitOverlayGroup items={WEB_DESIGN_FEATURES} />;
  }
}

export default WebsiteDesignFeatures;
