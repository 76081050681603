// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import Button from "fields/Button";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

class ProductDetail extends React.Component {
  renderButtons = () => (
    <div className={Style.buttons}>
      <Button
        className={Style.startNowBtn}
        hasShadow
        name="get started"
        onClick={() => this.props.history.push("/get-started")}
      >
        Get a Free Demo <ChevronArrowIcon className={Style.arrowIcon} />
      </Button>
    </div>
  );

  render() {
    return (
      <div className={Style.container}>
        <div className={Style.text_column}>
          <div className={Style.text_content}>
            <h2 className={Style.heading}>Product details</h2>

            <p className={Style.description}>
              <strong>Payments:</strong> Accepts contactless, chip, swipe, and
              debit PIN payments
              <br />
              <strong>Screens:</strong> 24" order touch screen and 8" payment
              touch screen
              <br />
              <strong>Receipt printer:</strong> Built-in thermal dot receipt
              printer
              <br />
              <strong>Security:</strong> Clover Security end-to-end encryption
              <br />
              <strong>Connectivity:</strong> Wi-Fi, ethernet, and LTE
              connectivity
              <br />
              <strong>Mount:</strong> Optional wall mount accessory and supports
              100mm VESA mounts
            </p>

          </div>
        </div>
        <div className={Style.image_column}>
          <img
            alt=""
            className={Style.hero_image}
            src="https://images.ctfassets.net/v6ivjcl8qjz2/70qylNjX2oCt1Pn5xEXm6B/1b9032903f0ad81b220aae9665d6b85b/kiosk-side-by-side-diagram__2_.webp?fit=scale&fm=webp"
          />
        </div>
      </div>
    );
  }
}

ProductDetail.propTypes = {
  context: PropTypes.shape({
    onScrollTo: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(ProductDetail)
);
