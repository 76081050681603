// BUTI DINERS, INC. All right Reserved ©

import React from "react";

import {
  CarIcon,
  CateringIcon,
  DiningTableWithChairsIcon,
  WavingHandIcon,
} from "assets/Icons";

import STRIPE from "./Stripe";
import {
  MAX_SUB_NAVBAR_ITEMS,
  MOBILE_NAVBAR_ITEMS,
  ORDERS_NAVBAR_ITEMS,
} from "./NavbarItems";
import { DIETARY_RESTRICTIONS, FOOD_PREFERENCES } from "./FoodPreferences";
import {
  AVERAGE_PREPARATION_TIMES,
  COUPON_INFO_SECTION,
  CUSTOMER_TIPPING_OPTIONS,
  NEW_GROUP_FIELDS,
  NEW_ITEM_FIELDS,
  NEW_MODIFIER_FIELDS,
  NEW_MODIFIER_GROUP_FIELDS,
  NEW_PAYMENT_CARD_FIELDS,
  OPPORTUNITIES,
  PICKUP_ORDER_PREPARATION_TIMES,
  SALES_REPORT_FILTER_OPTIONS,
  SERVICES_PRICING,
  SHOP_BANK_ACCOUNT_FIELDS,
  SHOP_INFO_SECTIONS,
} from "./Miscellaneous";
import ROUTES from "./Routes";
import RegEx from "./RegEx";
import US_STATES from "./US_States";

const ORDER_DELIVERY_TYPES = {
  catering: { label: "Catering", icon: <CateringIcon /> },
  inStore: { label: "Dine In", icon: <DiningTableWithChairsIcon /> },
  pickUp: { label: "Pickup", icon: <WavingHandIcon /> },
  deliver: { label: "Delivery", icon: <CarIcon /> },
};

const DAYS_IN_A_WEEK = {
  monday: { id: 1 },
  tuesday: { id: 2 },
  wednesday: { id: 3 },
  thursday: { id: 4 },
  friday: { id: 5 },
  saturday: { id: 6 },
  sunday: { id: 0 },
};

const DATE_FORMAT = "MMM-DD-YYYY";
const DEFAULT_TIMEZONE = "America/New_York";
const TIME_FORMAT = "hh:mm A";
const TEST_SHOP_IDS = [
  "-Lbe5A3_INAGsRo6oT6v",
  "-Ldni3ONVYI4zUyAPlIs",
  "-LmGuqY0-ArNrVLK0tSx",
];
const TIME_RANGE_OPTIONS = {
  today: { label: "today", description: "today" },
  "1d": { label: "1d", description: "yesterday" },
  "1w": { label: "1w", description: "1 week" },
  "4w": { label: "4w", description: "4 weeks" },
  all: { label: "All", description: "all time" },
};

const CUSTOMER_REWARD_TYPES = {
  ON_SALE_ITEMS: "Items on Sale",
  COUPONS: "Coupons",
};

const CUSTOMER_REWARD_TYPES_IMG_URLS = {
  ON_SALE_ITEMS: "https://i.imgur.com/MWoheLN.jpg",
  COUPONS: "https://i.imgur.com/oTVRhYM.jpg",
};

const COUPON_TYPES = {
  BUY_ONE_GET_ONE: {
    description: "Select items from the menu to offer buy one, get one on",
    header: "Buy One, Get One",
  },
  DISCOUNT_ON_ORDERS_ABOVE_X: {
    description: "Set a flat discount if the price exceeds an amount $X",
    header: "Discount on orders above $X",
  },
  COMBOS: {
    description: "Create combos on different items from the menu",
    header: "Combos",
    comingSoon: true,
  },
  DISCOUNT_ON_TOTAL_AMOUNT: {
    description: "Set a discount on the total amount of the order",
    header: "Discount on final Order",
    comingSoon: true,
  },
};

const BOGO_CHOICES = {
  sameCategory: {
    label: "Same category",
    example: "Buy one, get one on Burgers",
  },
  differentCategories: {
    label: "Different categories",
    example: "Buy one Burger, get one Side free",
  },
};

const SHOPS_NOT_ALLOW_DINE_IN = [
  "ZpdAUBVGd",
  "wqkG0hqG8",
  "1qnzPJ-ib",
  "273266u",
  "587846h",
  "736249e",
  "310085j",
  "602404g",
  "CqppRCeDh",
  "aQIx3Mt-s",
  "118638g",
  "352795g",
  "-M1DAWQVUIzYPraAjtNM",
  "C2nOX_tll",
  "402337c",
  "120197v",
  "dRz40ZobS",
  "383782o",
];

export {
  AVERAGE_PREPARATION_TIMES,
  BOGO_CHOICES,
  COUPON_INFO_SECTION,
  COUPON_TYPES,
  CUSTOMER_REWARD_TYPES,
  CUSTOMER_REWARD_TYPES_IMG_URLS,
  CUSTOMER_TIPPING_OPTIONS,
  ORDER_DELIVERY_TYPES,
  DAYS_IN_A_WEEK,
  DATE_FORMAT,
  DEFAULT_TIMEZONE,
  DIETARY_RESTRICTIONS,
  OPPORTUNITIES,
  FOOD_PREFERENCES,
  MAX_SUB_NAVBAR_ITEMS,
  MOBILE_NAVBAR_ITEMS,
  NEW_GROUP_FIELDS,
  NEW_ITEM_FIELDS,
  NEW_MODIFIER_FIELDS,
  NEW_MODIFIER_GROUP_FIELDS,
  NEW_PAYMENT_CARD_FIELDS,
  ORDERS_NAVBAR_ITEMS,
  PICKUP_ORDER_PREPARATION_TIMES,
  RegEx,
  ROUTES,
  SALES_REPORT_FILTER_OPTIONS,
  SERVICES_PRICING,
  SHOP_BANK_ACCOUNT_FIELDS,
  SHOP_INFO_SECTIONS,
  SHOPS_NOT_ALLOW_DINE_IN,
  STRIPE,
  TEST_SHOP_IDS,
  TIME_FORMAT,
  TIME_RANGE_OPTIONS,
  US_STATES,
};
