// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import shortid from "shortid";
import { withRouter } from "react-router-dom";

import { CLOVER_OPTION } from "./constants";

// Style
import Style from "./style.module.scss";

// Fields
import Button from "fields/Button";

// Assets
import { ChevronArrowIcon, BagIcon } from "assets/Icons";
import CloverCheckOutModal from "components/Modals/CloverCheckOutModal";
import { ShowConfirmNotif } from "lib/functions";

class CloverOption extends React.Component {
  state = {
    cart: [],
    cart_quantity: 0,
    show_checkout: false,
  };

  componentDidMount = () => this.updateCart();

  onShowCheckout = () => this.setState({ show_checkout: true });

  onCloseCheckout = () => this.setState({ show_checkout: false });

  updateCart = () => {
    const cart = JSON.parse(localStorage.getItem("clover_cart")) || [];
    const cart_quantity = cart.reduce((acc, item) => acc + item.quantity, 0);
    this.setState({ cart, cart_quantity });
  };

  renderBenefitDetailsList = (list = []) =>
    list.map((item) => {
      const { description = "", name = "", price = 0 } = item;
      return (
        <div key={shortid.generate()}>
          <div className={Style.list_item_name}>{name}</div>
          <div className={Style.list_item_price}>${price}</div>
          <p className={Style.list_item_description}>{description}</p>
        </div>
      );
    });

  addToCart = (benefit_id) => {
    const { img_src = "", list = [] } = CLOVER_OPTION[benefit_id];
    const product = {
      id: benefit_id,
      name: list[0].name,
      img: img_src,
      price: list[0].price,
      quantity: 1,
    };
    const cart = JSON.parse(localStorage.getItem("clover_cart")) || [];
    const existing_product_index = cart.findIndex(
      (item) => item.name === product.name
    );

    if (existing_product_index >= 0) {
      cart[existing_product_index].quantity += 1;
    } else {
      cart.push(product);
    }
    localStorage.setItem("clover_cart", JSON.stringify(cart));
    this.updateCart();

    ShowConfirmNotif({
      message: `${product.name} has been added to your cart.`,
      type: "success",
    });
  };

  renderBenefits = () =>
    Object.keys(CLOVER_OPTION).map((benefit_id) => {
      const { img_src = "", list = {} } = CLOVER_OPTION[benefit_id];
      return (
        <div className={Style.benefit_card} key={benefit_id}>
          <div className={Style.benefit_name_container}>
            <img alt="" className={Style.icon} src={img_src} />
          </div>
          <div className={Style.benefit_details_list}>
            {this.renderBenefitDetailsList(list)}
          </div>
          <Button
            className={Style.learn_more_button}
            name="Learn More Button"
            onClick={() => this.addToCart(benefit_id)}
          >
            Add To Cart <ChevronArrowIcon className={Style.arrow_icon} />
          </Button>
        </div>
      );
    });

  render() {
    const { show_checkout = false, cart_quantity = 0, cart = [] } = this.state;
    return (
      <div className={Style.container}>
        <h2 className={Style.title}>You've got options</h2>
        <div className={Style.benefits_container}>{this.renderBenefits()}</div>
        {cart_quantity > 0 && (
          <div className={Style.cart_bubble} onClick={this.onShowCheckout}>
            <BagIcon />
            <span className={Style.cart_quantity}>{cart_quantity}</span>
          </div>
        )}

        {show_checkout && (
          <CloverCheckOutModal
            onCloseCheckout={this.onCloseCheckout}
            cart={cart}
            updateCart={this.updateCart}
          />
        )}
      </div>
    );
  }
}

export default withRouter(CloverOption);
