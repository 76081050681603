// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Introduction from "./Introduction";
import Features from "./Features";

// Utils
import { _updatePageTitleAndDescription } from "utils";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Components
import { LetsChatBox } from "components";
import ProductDetail from "./ProductDetail";
import CustomerExperience from "./CustomerExperience";
import CloverOption from "./CloverOption";

class CloverKiosk extends React.Component {
  componentDidMount = () => {
    _updatePageTitleAndDescription({
      description: "",
      title: "SKIPLI | Clover Kiosk",
    });
  };

  render() {
    return (
      <div className={Style.pageContainer}>
        <Introduction />
        <Features />
        <ProductDetail />
        <CustomerExperience />
        <CloverOption />
        <div style={{ marginTop: "4rem" }}>
          <div className={Style.sectionContainer}>
            <LetsChatBox
              heading="Ready to boost your business?"
              description="Build your POS System now."
            />
          </div>
        </div>
      </div>
    );
  }
}

CloverKiosk.propTypes = {
  context: PropTypes.shape({
    onUpdateRefs: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(withRouter(CloverKiosk));
