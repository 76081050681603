// BUTI DINERS, INC. All right Reserved ©

const HOME_PAGE_NAV_OPTIONS = {
  // compare: {
  //   label: "Compare",
  // },
  delivery: {
    label: "Online Ordering",
    pathname: "/delivery",
  },
  google_front_page: {
    label: "Google Front Page",
    pathname: "/google-front-page",
  },
  auto_phone_answering: {
    label: "Auto Phone Answering",
    pathname: "/auto-phone",
  },
  // virtualStore: {
  //   label: "Virtual Restaurant",
  //   pathname: "/virtual-restaurant",
  // },
  // contactlessOrdering: {
  //   label: "Contactless Ordering",
  //   pathname: "contactless",
  //   isNew: false,
  // },
  // websiteDesign: {
  //   label: "Website Design",
  //   pathname: "/website-design",
  // },
  examples: {
    label: "Website Examples",
    pathname: "/website-examples",
  },
  blog: {
    label: "Blog",
    pathname: "/blog",

  },
  testimonials: {
    label: "Testimonials",
    pathname: "/testimonials",
  },
  clover_kiosk: {
    label: "Clover Kiosk",
    pathname: "/clover-kiosk",
    isNew: true,

  },
  // pricing: {
  //   label: "Pricing",
  //   pathname: "pricing",
  // },
  getStarted: {
    label: "Get A Free Demo",
    pathname: "/get-started",
  },
  // orderingPlatform: {
  //   label: "Order Food",
  //   pathname: "/order-food",
  // },
};

export { HOME_PAGE_NAV_OPTIONS };
