// BUTI DINERS, INC. All right Reserved ©

// Assets
import clover_wall from "assets/Images/clover_wall.png";
import clover_countertop from "assets/Images/clover_countertop.png";
import clover_floor_stand from "assets/Images/clover_floor_stand.jpg";

const CLOVER_OPTION = {
  floor_stand: {
    img_src: clover_floor_stand,
    list: [
      {
        name: "Floor stand",
        price: 3250,
        description:
          "The Floor stand accessory has high and low setting options for people who are non-disabled or use wheelchairs.",
      },
    ],
  },
  wall_mounted: {
    img_src: clover_wall,
   
    list: [
      {
        name: "Wall mounted",
        price: 2950,
        description:
          "Clover’s optional bracket accessory or any third-party 100mm VESA mount make wall mounting simple. ",
      },
    ],
  },
  counter_top: {
    img_src: clover_countertop,
    list: [
      {
        price: 2850,
        name: "Countertop",
        description:
          "Take it out of the box, put it on the counter top, and follow the guided set up. Ready for orders.",
      },
    ],
  },
};

export { CLOVER_OPTION };
